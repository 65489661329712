import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/analytics/",
};

export default {
  getDashboardStats(timePeriod, department) {
    let url = urlList.urlRoot + "dashboard-stats/";

    if (timePeriod) {
      url += `?time_period=${timePeriod}`;
    }
    if (department) {
      url += `&department=${department}`;
    }

    return apiClient.get(url);
  },

  getJobsRevenueByProductType(productTypeSlug, timePeriod, perPage, page) {
    let url =
      urlList.urlRoot +
      "dashboard-stats/" +
      `product-types/${productTypeSlug}/jobs-revenue/${timePeriod}/` +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    return apiClient.get(url);
  },

  getJobMetrics(jobUUID) {
    let url = urlList.urlRoot + `job-stats/${jobUUID}/job-metrics/`;
    return apiClient.get(url);
  },
};
