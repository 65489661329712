import ServiceBilling from "../../service/billing/ServiceBilling.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  externalApiTransactionList: [],
  externalApiTransactionCount: null,
};

export const mutations = {
  SET_EXTERNAL_API_TRANSACTION_LIST(state, data) {
    state.externalApiTransactionList = data;
  },

  SET_EXTERNAL_API_TRANSACTION_COUNT(state, count) {
    state.externalApiTransactionCount = count;
  },
};

export const actions = {
  getExternalApiTransactionList(
    { commit },
    { perPage, page, month, year, searchQuery }
  ) {
    return ServiceBilling.getExternalApiTransactionList(
      perPage,
      page,
      month,
      year,
      searchQuery
    )
      .then((resp) => {
        commit("SET_EXTERNAL_API_TRANSACTION_LIST", resp.data.results);
        commit("SET_EXTERNAL_API_TRANSACTION_COUNT", resp.data.count);
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCompanyExternalTransactionSummary({}, { company_uuid, month, year }) {
    return ServiceBilling.getCompanyExternalTransactionSummary(
      company_uuid,
      month,
      year
    )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
