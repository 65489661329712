import ServiceDataExim from "../../service/data_exim/ServiceDataExim.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  customersList: [],
  customersCount: null,

  vehiclesList: [],
  vehiclesCount: null,

  importedInvoicesList: [],
  importedInvoicesCount: null,

  importedInvoicePdfTaskId: null,
};

export const mutations = {
  SET_IMPORTED_CUSTOMERS_LIST(state, data) {
    state.customersList = data;
  },

  SET_IMPORTED_CUSTOMERS_COUNT(state, count) {
    state.customersCount = count;
  },

  SET_IMPORTED_VEHICLES_LIST(state, data) {
    state.vehiclesList = data;
  },

  SET_IMPORTED_VEHICLES_COUNT(state, count) {
    state.vehiclesCount = count;
  },

  SET_IMPORTED_INVOICES_LIST(state, data) {
    state.importedInvoicesList = data;
  },

  SET_IMPORTED_INVOICES_COUNT(state, count) {
    state.importedInvoicesCount = count;
  },

  SAVE_IMPORTED_INVOICE_PDF_TASK_ID(state, taskId) {
    state.importedInvoicePdfTaskId = taskId;
  },

  CLEAR_IMPORTED_INVOICE_PDF_TASK_ID(state) {
    state.importedInvoicePdfTaskId = null;
  },
};

export const actions = {
  getImportedCustomersList({ commit }, { perPage, page, searchQuery }) {
    return ServiceDataExim.getImportedCustomersList(perPage, page, searchQuery)
      .then((resp) => {
        commit("SET_IMPORTED_CUSTOMERS_LIST", resp.data.results);
        commit("SET_IMPORTED_CUSTOMERS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getImportedVehiclesList({ commit }, { perPage, page, searchQuery }) {
    return ServiceDataExim.getImportedVehiclesList(perPage, page, searchQuery)
      .then((resp) => {
        commit("SET_IMPORTED_VEHICLES_LIST", resp.data.results);
        commit("SET_IMPORTED_VEHICLES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getImportedInvoicesList(
    { commit },
    {
      perPage,
      page,
      searchQuery,
      customerObjId,
      importedCustomerObjId,
      vehicleObjId,
      importedVehicleObjId,
    }
  ) {
    return ServiceDataExim.getImportedInvoicesList(
      perPage,
      page,
      searchQuery,
      customerObjId,
      importedCustomerObjId,
      vehicleObjId,
      importedVehicleObjId
    )
      .then((resp) => {
        commit("SET_IMPORTED_INVOICES_LIST", resp.data.results);
        commit("SET_IMPORTED_INVOICES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveImportedInvoice({}, invoiceUUID) {
    return ServiceDataExim.retrieveImportedInvoice(invoiceUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateImportedInvoicePDF({ commit }, { invoiceUUID, templateName }) {
    return ServiceDataExim.generateImportedInvoicePDF(invoiceUUID, templateName)
      .then((resp) => {
        commit("SAVE_IMPORTED_INVOICE_PDF_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating imported invoice pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkImportedInvoicePDFGenerationTaskStatus(
    { commit },
    { invoiceUUID, formData }
  ) {
    return ServiceDataExim.checkImportedInvoicePDFGenerationTaskStatus(
      invoiceUUID,
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_IMPORTED_INVOICE_PDF_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
