import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/crm/billing/",
};

export default {
  getExternalApiTransactionList(perPage, page, month, year, searchQuery) {
    let url =
      urlList.urlRoot +
      "external-api-transactions/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page +
      "&month=" +
      month +
      "&year=" +
      year;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    return apiClient.get(url);
  },

  getCompanyExternalTransactionSummary(company_uuid, month, year) {
    let url =
      urlList.urlRoot +
      "external-api-transactions/" +
      "retrieve" +
      "?company_uuid=" +
      company_uuid +
      "&month=" +
      month +
      "&year=" +
      year;

    return apiClient.get(url);
  },
};
