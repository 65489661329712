import Vue from "vue";
import Vuex from "vuex";

import * as analytics from "./analytics/analytics.js";
import * as contacts from "./contacts/contacts.js";
import * as company from "./company/company.js";
import * as company_user from "./company_user/company_user.js";
import * as data_exim from "./data_exim/data_exim.js";
import * as user from "./user/user.js";
import * as invitations from "./invitations/invitations.js";
import * as workshop from "./workshop/workshop.js";
import * as settings_admin from "./settings/settings_admin.js";
import * as settings_crm from "./settings/settings_crm.js";
import * as inventory from "./inventory/inventory.js";
import * as invoices from "./invoices/invoices.js";
import * as vehicles from "./vehicles/vehicles.js";
import * as xero_manager from "./xero_manager/xero_manager.js";
import * as quotes from "./quotes/quotes.js";
import * as reports from "./reports/reports.js";
import * as notifier from "./notifier/notifier.js";
import * as billing from "./billing/billing.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    analytics,
    contacts,
    company,
    company_user,
    data_exim,
    user,
    invitations,
    workshop,
    settings_admin,
    settings_crm,
    inventory,
    invoices,
    vehicles,
    xero_manager,
    quotes,
    reports,
    notifier,
    billing,
  },
});
