import ServiceUser from "../../service/user/ServiceUser.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  showUniversalSearchModal: false,
  demoBookingList: [],
  demoBookingCount: null,

  signupFormDataList: [],
  signupFormDataCount: null,
};

export const mutations = {
  TOGGLE_SEARCH_MODAL_DISPLAY(state) {
    if (state.showUniversalSearchModal == false) {
      state.showUniversalSearchModal = true;
    } else {
      state.showUniversalSearchModal = false;
    }
  },
  SET_DEMO_BOOKING_LIST(state, demoBookingList) {
    state.demoBookingList = demoBookingList;
  },
  SET_DEMO_BOOKING_COUNT(state, count) {
    state.demoBookingCount = count;
  },
  UPDATE_DEMO_BOOKING(state, demoBookingObj) {
    state.demoBookingList = state.demoBookingList.map((element) => {
      if (element.id === demoBookingObj.id) {
        element = JSON.parse(JSON.stringify(demoBookingObj));
      }
      return element;
    });
  },
  DELETE_DEMO_BOOKING(state, bookingUUID) {
    state.demoBookingList = state.demoBookingList.filter((element) => {
      if (element.booking_uuid != bookingUUID) {
        return element;
      }
    });
  },
  DECREASE_DEMO_BOOKING_COUNT(state) {
    state.demoBookingCount = state.demoBookingCount - 1;
  },
  SET_SIGNUP_FORMDATA_LIST(state, signupFormDataList) {
    state.signupFormDataList = signupFormDataList;
  },
  SET_SIGNUP_FORMDATA_COUNT(state, count) {
    state.signupFormDataCount = count;
  },
  UPDATE_SIGNUP_FORMDATA(state, signupFomrDataObj) {
    state.signupFormDataList = state.signupFormDataList.map((element) => {
      if (element.id === signupFomrDataObj.id) {
        element = JSON.parse(JSON.stringify(signupFomrDataObj));
      }
      return element;
    });
  },
  DELETE_SIGNUP_FORMDATA(state, signupFormDataUUID) {
    state.signupFormDataList = state.signupFormDataList.filter((element) => {
      if (element.signup_uuid != signupFormDataUUID) {
        return element;
      }
    });
  },
  DECREASE_SIGNUP_FORMDATA_COUNT(state) {
    state.signupFormDataCount = state.signupFormDataCount - 1;
  },
};

export const actions = {
  updateUser({}, { userUUID, formData, actionType }) {
    return ServiceUser.updateUser(userUUID, formData)
      .then((resp) => {
        if (actionType == "profile") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Profile updated successfully",
          });
        } else if (actionType == "user") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "User updated successfully",
          });
        } else if (actionType == "image-upload") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Profile Image uploaded successfully",
          });
        } else if (actionType == "image-delete") {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Delete",
            message: "Profile Image deleted successfully",
          });
        } else if (actionType == "notification") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "User notification settings updated successfully",
          });
        } else if (actionType == "phone_number") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Phone number updated successfully",
          });
        } else if (actionType == "email") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Email updated successfully",
          });
        }

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.username) {
            message = "Username: " + err.response.data.username;
          } else if (err.response.data.first_name) {
            message = "First Name: " + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name: " + err.response.data.last_name;
          } else if (err.response.data.dob) {
            message = "Date of Birth: " + err.response.data.dob;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.image) {
            message = "Profile Image: " + err.response.data.image;
          } else if (err.response.data.labour_cost) {
            message = "Labour Cost: " + err.response.data.labour_cost;
          } else if (err.response.data.labour_rate) {
            message = "Labour Rate: " + err.response.data.labour_rate;
          } else if (err.response.data.display_in_dashboard) {
            message =
              "Display In Dashboard: " + err.response.data.display_in_dashboard;
          } else if (err.response.data.is_mechanic) {
            message = "Is Mechanic: " + err.response.data.is_mechanic;
          } else if (err.response.data.login_redirect_page) {
            message =
              "Login Redirect Page: " + err.response.data.login_redirect_page;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  confirmCurrentUserInfoChange({}, formData) {
    return ServiceUser.confirmCurrentUserInfoChange(formData)
      .then((resp) => {
        let message = resp.data.message;
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: message,
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.changed_field_name) {
            message = "Changed Field: " + err.response.data.changed_field_name;
          } else if (err.response.data.user_password) {
            message = "Password: " + err.response.data.user_password;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  verifyOtpToConfirmCurrentUserInfoChange({}, formData) {
    return ServiceUser.verifyOtpToConfirmCurrentUserInfoChange(formData)
      .then((resp) => {
        let message = resp.data.message;
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: message,
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.changed_field_name) {
            message = "Changed Field: " + err.response.data.changed_field_name;
          } else if (err.response.data.user_password) {
            message = "Password: " + err.response.data.user_password;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  changeUserPassword({}, formData) {
    return ServiceUser.changeUserPassword(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Password changed successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.old_password) {
            message = "Old Password: " + err.response.data.old_password;
          } else if (err.response.data.new_password) {
            message = "New Password: " + err.response.data.new_password;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  signupUser({}, formData) {
    return ServiceUser.signupUser(formData)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.password) {
            message = "Password: " + err.response.data.password;
          } else if (err.response.data.first_name) {
            message = "First Name: " + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name: " + err.response.data.last_name;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  loginUser({}, formData) {
    return ServiceUser.loginUser(formData)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.company_handle) {
            message = "Company Handle: " + err.response.data.company_handle;
          } else if (err.response.data.password) {
            message = "Password: " + err.response.data.password;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  verifyOtpTologinUser({}, formData) {
    return ServiceUser.verifyOtpTologinUser(formData)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.otp_token) {
            message = err.response.data.otp_token;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else if (err.response.status == 404) {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Invalid OTP",
          });
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  resendOtpTologinUser({}, formData) {
    return ServiceUser.resendOtpTologinUser(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "OTP resend successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.otp_token) {
            message = err.response.data.otp_token;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // password reset
  resetPassword({}, data) {
    return ServiceUser.resetPassword(data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.company_handle) {
            message = "Company Handle: " + err.response.data.company_handle;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  resetPasswordConfirm({}, data) {
    return ServiceUser.resetPasswordConfirm(data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  resetPasswordValidateToken({}, data) {
    return ServiceUser.resetPasswordValidateToken(data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // demo booking
  getDemoBookingFormData({}) {
    return ServiceUser.getDemoBookingFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
  },

  getDemoBookingList(
    { commit },
    { perPage, page, searchQuery, status, bookingTimeFrom, bookingTimeTo }
  ) {
    return ServiceUser.getDemoBookingList(
      perPage,
      page,
      searchQuery,
      status,
      bookingTimeFrom,
      bookingTimeTo
    )
      .then((resp) => {
        commit("SET_DEMO_BOOKING_LIST", resp.data.results);
        commit("SET_DEMO_BOOKING_COUNT", resp.data.count);
      })
      .catch((err) => {
        return err.response;
      });
  },

  retrieveDemoBooking({}, bookingUUID) {
    return ServiceUser.retrieveDemoBooking(bookingUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateDemoBooking({ commit }, { bookingUUID, formData }) {
    return ServiceUser.updateDemoBooking(bookingUUID, formData)
      .then((resp) => {
        commit("UPDATE_DEMO_BOOKING", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Demo Booking updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.company_name) {
            message = "Company Name: " + err.response.data.company_name;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.email) {
            message = "Email:" + err.response.data.email;
          } else if (err.response.data.first_name) {
            message = "First Name:" + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name:" + err.response.data.last_name;
          } else if (err.response.data.booking_time) {
            message = "Booking Time:" + err.response.data.booking_time;
          } else if (err.response.data.status) {
            message = "Status:" + err.response.data.status;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
          return err.response;
        }
      });
  },

  deleteDemoBooking({ commit }, bookingUUID) {
    return ServiceUser.deleteDemoBooking(bookingUUID)
      .then((resp) => {
        commit("DELETE_DEMO_BOOKING", bookingUUID);
        commit("DECREASE_DEMO_BOOKING_COUNT");
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Demo Booking deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },

  // signup form data

  getSignupFormData({}) {
    return ServiceUser.getSignupFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
  },

  getSignupFormDataList(
    { commit },
    { perPage, page, searchQuery, isSignedUp, subscriptionIDs }
  ) {
    return ServiceUser.getSignupFormDataList(
      perPage,
      page,
      searchQuery,
      isSignedUp,
      subscriptionIDs
    )
      .then((resp) => {
        commit("SET_SIGNUP_FORMDATA_LIST", resp.data.results);
        commit("SET_SIGNUP_FORMDATA_COUNT", resp.data.count);
      })
      .catch((err) => {
        return err.response;
      });
  },

  retrieveSignupFormData({}, signupFormDataUUID) {
    return ServiceUser.retrieveSignupFormData(signupFormDataUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateSignupFormData({ commit }, { signupFormDataUUID, formData }) {
    return ServiceUser.updateSignupFormData(signupFormDataUUID, formData)
      .then((resp) => {
        commit("UPDATE_SIGNUP_FORMDATA", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Signup Form Data updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.company_name) {
            message = "Company Name: " + err.response.data.company_name;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.email) {
            message = "Email:" + err.response.data.email;
          } else if (err.response.data.first_name) {
            message = "First Name:" + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name:" + err.response.data.last_name;
          } else if (err.response.data.subscription_id) {
            message = "Subscription ID:" + err.response.data.subscription_id;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
          return err.response;
        }
      });
  },

  deleteSignupFormData({ commit }, signupFormDataUUID) {
    return ServiceUser.deleteSignupFormData(signupFormDataUUID)
      .then((resp) => {
        commit("DELETE_SIGNUP_FORMDATA", signupFormDataUUID);
        commit("DECREASE_SIGNUP_FORMDATA_COUNT");
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Demo Booking deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },

  executeSignup({ commit }, formData) {
    return ServiceUser.executeSignup(formData)
      .then((resp) => {
        commit("UPDATE_SIGNUP_FORMDATA", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Signup executed successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },
};
