import ServiceAnalytics from "../../service/analytics/ServiceAnalytics.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  jobsRevenueByProductTypeList: [],
  jobsRevenueByProductTypeCount: null,
};

export const mutations = {
  SET_JOBS_REVENUE_BY_PRODUCT_TYPE_LIST(state, data) {
    state.jobsRevenueByProductTypeList = data;
  },

  SET_JOBS_REVENUE_BY_PRODUCT_TYPE_COUNT(state, count) {
    state.jobsRevenueByProductTypeCount = count;
  },
};

export const actions = {
  getDashboardStats({}, { timePeriod, department }) {
    return ServiceAnalytics.getDashboardStats(timePeriod, department)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobsRevenueByProductType(
    { commit },
    { productTypeSlug, timePeriod, perPage, page }
  ) {
    return ServiceAnalytics.getJobsRevenueByProductType(
      productTypeSlug,
      timePeriod,
      perPage,
      page
    )
      .then((resp) => {
        commit(
          "SET_JOBS_REVENUE_BY_PRODUCT_TYPE_LIST",
          resp.data.results.jobs_data
        );
        commit("SET_JOBS_REVENUE_BY_PRODUCT_TYPE_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobMetrics({}, jobUUID) {
    return ServiceAnalytics.getJobMetrics(jobUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
