import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/",
  urlPasswordReset: "/api/v1/password_reset/",
};

export default {
  updateUser(userUUID, formData) {
    let url = urlList.urlRoot + `users/${userUUID}/`;
    return apiClient.patch(url, formData);
  },

  confirmCurrentUserInfoChange(formData) {
    let url = urlList.urlRoot + `users/current/info/confirm-change/`;
    return apiClient.post(url, formData);
  },

  verifyOtpToConfirmCurrentUserInfoChange(formData) {
    let url = urlList.urlRoot + `users/current/info/confirm-change/verify-otp/`;
    return apiClient.post(url, formData);
  },

  changeUserPassword(formData) {
    let url = urlList.urlRoot + "users/change-password/";
    return apiClient.post(url, formData);
  },

  signupUser(formData) {
    let url = urlList.urlRoot + "signup/";
    return apiClient.post(url, formData);
  },

  loginUser(formData) {
    let url = urlList.urlRoot + "login/";
    return apiClient.post(url, formData);
  },

  verifyOtpTologinUser(formData) {
    let url = urlList.urlRoot + "login/verify-otp/";
    return apiClient.post(url, formData);
  },

  resendOtpTologinUser(formData) {
    let url = urlList.urlRoot + "login/resend-otp/";
    return apiClient.post(url, formData);
  },

  // password reset
  resetPassword(data) {
    return apiClient.post(urlList.urlPasswordReset, data);
  },

  resetPasswordConfirm(data) {
    let url = urlList.urlPasswordReset + "confirm/";
    return apiClient.post(url, data);
  },

  resetPasswordValidateToken(data) {
    let url = urlList.urlPasswordReset + "validate_token/";
    return apiClient.post(url, data);
  },

  // demo booking
  getDemoBookingFormData() {
    let url = urlList.urlRoot + "crm/demo-bookings/" + "form-data/";
    return apiClient.get(url);
  },

  getDemoBookingList(
    perPage,
    page,
    searchQuery,
    status,
    bookingTimeFrom,
    bookingTimeTo
  ) {
    let url =
      urlList.urlRoot +
      "crm/demo-bookings/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page;
    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    if (status) {
      url += "&status=" + status;
    }

    if (bookingTimeFrom && bookingTimeTo) {
      url +=
        "&booking_time_date_from=" +
        bookingTimeFrom +
        "&booking_time_date_to=" +
        bookingTimeTo;
    }
    return apiClient.get(url);
  },

  retrieveDemoBooking(bookingUUID) {
    let url = urlList.urlRoot + "crm/demo-bookings/" + `${bookingUUID}/`;
    return apiClient.get(url);
  },

  updateDemoBooking(bookingUUID, formData) {
    let url = urlList.urlRoot + "crm/demo-bookings/" + `${bookingUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteDemoBooking(bookingUUID) {
    let url = urlList.urlRoot + "crm/demo-bookings/" + `${bookingUUID}/`;
    return apiClient.delete(url);
  },

  // signup formdata

  getSignupFormData() {
    let url = urlList.urlRoot + "crm/signup-data/" + "form-data/";
    return apiClient.get(url);
  },

  getSignupFormDataList(
    perPage,
    page,
    searchQuery,
    isSignedUp,
    subscriptionIDs
  ) {
    let url =
      urlList.urlRoot +
      "crm/signup-data/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    if (isSignedUp == true) {
      url += "&is_signed_up=" + "true";
    }

    if (subscriptionIDs) {
      url += "&subscription_id=" + subscriptionIDs;
    }
    return apiClient.get(url);
  },

  retrieveSignupFormData(signupFormDataUUID) {
    let url = urlList.urlRoot + "crm/signup-data/" + `${signupFormDataUUID}/`;
    return apiClient.get(url);
  },

  updateSignupFormData(signupFormDataUUID, formData) {
    let url = urlList.urlRoot + "crm/signup-data/" + `${signupFormDataUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteSignupFormData(signupFormDataUUID) {
    let url = urlList.urlRoot + "crm/signup-data/" + `${signupFormDataUUID}/`;
    return apiClient.delete(url);
  },

  executeSignup(formData) {
    let url = urlList.urlRoot + "crm/signup-data/" + "execute-signup/";
    return apiClient.post(url, formData);
  },
};
