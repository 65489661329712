import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/imported-data/",
};

export default {
  getImportedCustomersList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "customers/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getImportedVehiclesList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "vehicles/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getImportedInvoicesList(
    perPage,
    page,
    searchQuery,
    customerObjId,
    importedCustomerObjId,
    vehicleObjId,
    importedVehicleObjId
  ) {
    let url =
      urlList.urlRoot + "invoices/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    if (customerObjId) {
      url += "&customer_id=" + customerObjId;
    } else if (importedCustomerObjId) {
      url += "&imported_customer_id=" + importedCustomerObjId;
    }

    if (vehicleObjId) {
      url += "&vehicle_id=" + vehicleObjId;
    } else if (importedVehicleObjId) {
      url += "&imported_vehicle_id=" + importedVehicleObjId;
    }

    return apiClient.get(url);
  },

  retrieveImportedInvoice(invoiceUUID) {
    let url = urlList.urlRoot + `invoices/${invoiceUUID}/`;
    return apiClient.get(url);
  },

  generateImportedInvoicePDF(invoiceUUID, templateName) {
    let url = urlList.urlRoot + `invoices/${invoiceUUID}/generate-pdf/`;
    return apiClient.post(url, { template_name: templateName });
  },

  checkImportedInvoicePDFGenerationTaskStatus(invoiceUUID, formData) {
    let url = urlList.urlRoot + `invoices/${invoiceUUID}/pdf-task-status/`;
    return apiClient.post(url, formData);
  },
};
