import ServiceSettingsAdmin from "../../service/settings/ServiceSettingsAdmin.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  invoiceSenderEmailList: [],
};

export const mutations = {
  SET_INVOICE_SENDER_MAIL_LIST(state, data) {
    state.invoiceSenderEmailList = data;
  },

  ADD_INVOICE_SENDER_MAIL(state, mailObj) {
    state.invoiceSenderEmailList.unshift(mailObj);
  },

  UPDATE_INVOICE_SENDER_MAIL(state, mailObj) {
    state.invoiceSenderEmailList = state.invoiceSenderEmailList.map(
      (element) => {
        if (element.id === mailObj.id) {
          element = JSON.parse(JSON.stringify(mailObj));
        }
        return element;
      }
    );
  },

  DELETE_INVOICE_SENDER_MAIL(state, mailObjId) {
    state.invoiceSenderEmailList = state.invoiceSenderEmailList.filter(
      (element) => {
        if (element.id != mailObjId) {
          return element;
        }
      }
    );
  },
};

export const actions = {
  getCustomerSettings({}) {
    return ServiceSettingsAdmin.getCustomerSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCustomerSettings({}, formData) {
    return ServiceSettingsAdmin.updateCustomerSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.customer_email_prompt) {
            message =
              "Customer Email Prompt: " +
              err.response.data.customer_email_prompt;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // job settings
  retrieveCompanyJobSettings({}) {
    return ServiceSettingsAdmin.retrieveCompanyJobSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  fetchCompanyJobSettingFormData({}) {
    return ServiceSettingsAdmin.fetchCompanyJobSettingFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCompanyJobSettings({}, formData) {
    return ServiceSettingsAdmin.updateCompanyJobSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.book_by) {
            message = "Book By Type: " + err.response.data.book_by;
          } else if (err.response.data.duration_input_type) {
            message =
              "Duration Input Type: " + err.response.data.duration_input_type;
          } else if (err.response.data.time_selection_type) {
            message =
              "Time Selection Type: " + err.response.data.time_selection_type;
          } else if (err.response.data.break_times) {
            message = "Break Times: " + err.response.data.break_times;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // wof settings
  retrieveCompanyWofSettings({}) {
    return ServiceSettingsAdmin.retrieveCompanyWofSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCompanyWofSettings({}, formData) {
    return ServiceSettingsAdmin.updateCompanyWofSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "WOF Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.is_wof_enabled) {
            message = "WOF Enabled: " + err.response.data.is_wof_enabled;
          } else if (err.response.data.reminder_type) {
            message = "Reminder Type: " + err.response.data.reminder_type;
          } else if (err.response.data.job_days) {
            message = "Job Days: " + err.response.data.job_days;
          } else if (err.response.data.jobs_per_day) {
            message = "Jobs / Day: " + err.response.data.jobs_per_day;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // invoice
  getInvoiceSettingsFormData({}) {
    return ServiceSettingsAdmin.getInvoiceSettingsFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveInvoiceSettings({}) {
    return ServiceSettingsAdmin.retrieveInvoiceSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateInvoiceSettings({}, formData) {
    return ServiceSettingsAdmin.updateInvoiceSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invoice & Quote Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.gst_number) {
            message = "GST Number: " + err.response.data.gst_number;
          } else if (err.response.data.bank_account_info) {
            message =
              "Bank Account Info: " + err.response.data.bank_account_info;
          } else if (err.response.data.footer_note) {
            message = "Footer Note: " + err.response.data.footer_note;
          } else if (err.response.data.footer_greetings) {
            message = "Footer Greetings: " + err.response.data.footer_greetings;
          } else if (err.response.data.global_labour_cost) {
            message =
              "Global Labour Cost: " + err.response.data.global_labour_cost;
          } else if (err.response.data.global_labour_rate) {
            message =
              "Global Labour Rate: " + err.response.data.global_labour_rate;
          } else if (err.response.data.mechanic_screen_add_non_inventory_part) {
            message =
              "Mechanic Screen Add Non-Inventory Part: " +
              err.response.data.mechanic_screen_add_non_inventory_part;
          } else if (
            err.response.data.mechanic_screen_show_product_part_number
          ) {
            message =
              "Mechanic Screen Show Product Part Number: " +
              err.response.data.mechanic_screen_show_product_part_number;
          } else if (err.response.data.mechanic_screen_add_labour) {
            message =
              "Mechanic Screen Add Labour: " +
              err.response.data.mechanic_screen_add_labour;
          } else if (err.response.data.show_labour_total_charge) {
            message =
              "Show Labour Total Charge: " +
              err.response.data.show_labour_total_charge;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // service report
  retrieveServiceReportSettings({}) {
    return ServiceSettingsAdmin.retrieveServiceReportSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateServiceReportSettings({}, formData) {
    return ServiceSettingsAdmin.updateServiceReportSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Service Report Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.letter_head) {
            message = "Letter Head: " + err.response.data.letter_head;
          } else if (err.response.data.letter_subject) {
            message = "Letter Subject: " + err.response.data.letter_subject;
          } else if (err.response.data.letter_body) {
            message = "Letter Body: " + err.response.data.letter_body;
          } else if (err.response.data.signature_block) {
            message = "Signature Block: " + err.response.data.signature_block;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // invoice sender email settings
  getInvoiceSenderEmailListWithoutPagination({ commit }) {
    return ServiceSettingsAdmin.getInvoiceSenderEmailListWithoutPagination()
      .then((resp) => {
        commit("SET_INVOICE_SENDER_MAIL_LIST", resp.data);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addInvoiceSenderEmail({ commit }, formData) {
    return ServiceSettingsAdmin.addInvoiceSenderEmail(formData)
      .then((resp) => {
        commit("ADD_INVOICE_SENDER_MAIL", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invoice sender email added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateInvoiceSenderEmail({ commit }, { objId, formData }) {
    return ServiceSettingsAdmin.updateInvoiceSenderEmail(objId, formData)
      .then((resp) => {
        commit("UPDATE_INVOICE_SENDER_MAIL", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invoice sender email updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteInvoiceSenderEmail({ commit }, mailObjId) {
    return ServiceSettingsAdmin.deleteInvoiceSenderEmail(mailObjId)
      .then((resp) => {
        commit("DELETE_INVOICE_SENDER_MAIL", mailObjId);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Invoice sender email deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getInvoiceSenderEmailFormData() {
    return ServiceSettingsAdmin.getInvoiceSenderEmailFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // xero settings
  retrieveXeroSettings({}) {
    return ServiceSettingsAdmin.retrieveXeroSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateXeroSettings({}, formData) {
    return ServiceSettingsAdmin.updateXeroSettings(formData)
      .then((resp) => {
        if (resp.data.is_api_enabled) {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Xero API Integration is enabled successfully",
          });
        } else {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Xero API Integration is disabled successfully",
          });
        }
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.is_enabled) {
            message = err.response.data.is_enabled;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // notification settings
  retrieveCompanyNotificationSettings({}) {
    return ServiceSettingsAdmin.retrieveCompanyNotificationSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCompanyNotificationSettings({}, formData) {
    return ServiceSettingsAdmin.updateCompanyNotificationSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Company notification settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.is_company_sms_enabled) {
            message =
              "Company SMS: " + err.response.data.is_company_sms_enabled;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveCustomerNotificationSettings({}) {
    return ServiceSettingsAdmin.retrieveCustomerNotificationSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCustomerNotificationSettings({}, formData) {
    return ServiceSettingsAdmin.updateCustomerNotificationSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer notification settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.status_data) {
            message = err.response.data.status_data;
          } else if (err.response.data.custom_messages) {
            message = err.response.data.custom_messages[0];
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveDefaultCustomNotificationMessage({}) {
    return ServiceSettingsAdmin.retrieveDefaultCustomNotificationMessage()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // calendar settings
  retrieveCompanyCalendarSettings({}) {
    return ServiceSettingsAdmin.retrieveCompanyCalendarSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCompanyCalendarSettings({}, formData) {
    return ServiceSettingsAdmin.updateCompanyCalendarSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Jobs Calendar Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.business_hours) {
            message = "Business Hours: " + err.response.data.business_hours;
          } else if (err.response.data.business_days) {
            message = "Business Days: " + err.response.data.business_days;
          } else if (err.response.data.display_cash_sale_jobs) {
            message =
              "Display Cash Sale Jobs: " +
              err.response.data.display_cash_sale_jobs;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // Payment Settings
  getPaymentSettings({}) {
    return ServiceSettingsAdmin.getPaymentSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updatePaymentSettings({}, formData) {
    return ServiceSettingsAdmin.updatePaymentSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Payment Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.company) {
            message = "Company: " + err.response.data.company;
          } else if (err.response.data.payment_settings) {
            message = "Payment Settings: " + err.response.data.payment_settings;
          } else if (err.response.data.paymentmethod_set) {
            message = "Payment Method: " + err.response.data.paymentmethod_set;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }
        return err.response;
      });
  },
};
