import ServiceInvoices from "../../service/invoices/ServiceInvoices.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  xeroAccountsList: [],
  xeroAccountsCount: null,

  xeroCurrenciesList: [],
  xeroCurrenciesCount: null,

  workshopInvoicesCount: null,
  workshopInvoicesList: [],
  workshopInvoicePdfTaskId: null,
  workshopInvoiceCreateTaskId: null,
  xeroInvoiceTaskId: null,

  workshopCompletedInvoicesList: [],
  workshopCompletedInvoiceCount: null,
};

export const mutations = {
  // xero account
  SET_XERO_ACCOUNTS_LIST(state, data) {
    state.xeroAccountsList = data;
  },

  SET_XERO_ACCOUNTS_COUNT(state, count) {
    state.xeroAccountsCount = count;
  },

  UPDATE_XERO_ACCOUNT(state, xeroAccountObj) {
    state.xeroAccountsList = state.xeroAccountsList.map((element) => {
      if (element.id === xeroAccountObj.id) {
        element = JSON.parse(JSON.stringify(xeroAccountObj));
      }
      return element;
    });
  },

  // xero currency
  SET_XERO_CURRENCIES_LIST(state, data) {
    state.xeroCurrenciesList = data;
  },

  SET_XERO_CURRENCIES_COUNT(state, count) {
    state.xeroCurrenciesCount = count;
  },

  UPDATE_XERO_CURRENCY(state, xeroCurrencyObj) {
    state.xeroCurrenciesList = state.xeroCurrenciesList.map((element) => {
      if (element.id === xeroCurrencyObj.id) {
        element = JSON.parse(JSON.stringify(xeroCurrencyObj));
      }
      return element;
    });
  },

  // workshop invoices
  SET_WORKSHOP_INVOICES_LIST(state, data) {
    state.workshopInvoicesList = data;
  },

  SET_WORKSHOP_INVOICES_COUNT(state, count) {
    state.workshopInvoicesCount = count;
  },

  ADD_WORKSHOP_INVOICE(state, workshopInvoiceObj) {
    state.workshopInvoicesList.unshift(workshopInvoiceObj);
  },

  UPDATE_WORKSHOP_INVOICE(state, workshopInvoiceObj) {
    state.workshopInvoicesList = state.workshopInvoicesList.map((element) => {
      if (element.id === workshopInvoiceObj.id) {
        element = JSON.parse(JSON.stringify(workshopInvoiceObj));
      }
      return element;
    });
  },

  SAVE_INVOICE_PDF_TASK_ID(state, taskId) {
    state.workshopInvoicePdfTaskId = taskId;
  },

  CLEAR_INVOICE_PDF_TASK_ID(state) {
    state.workshopInvoicePdfTaskId = null;
  },

  SAVE_INVOICE_CREATE_TASK_ID(state, taskId) {
    state.workshopInvoiceCreateTaskId = taskId;
  },

  CLEAR_INVOICE_CREATE_TASK_ID(state) {
    state.workshopInvoiceCreateTaskId = null;
  },

  SAVE_XERO_INVOICE_TASK_ID(state, taskId) {
    state.xeroInvoiceTaskId = taskId;
  },

  CLEAR_XERO_INVOICE_TASK_ID(state) {
    state.xeroInvoiceTaskId = null;
  },

  SET_WORKSHOP_COMPLETED_INVOICE_LIST(state, data) {
    state.workshopCompletedInvoicesList = data;
  },

  SET_WORKSHOP_COMPLETED_INVOICE_COUNT(state, count) {
    state.workshopCompletedInvoiceCount = count;
  },
};

export const actions = {
  // workshop invoices
  getWorkshopInvoicesList(
    { commit },
    { perPage, page, searchQuery, customerUUID, statuses }
  ) {
    return ServiceInvoices.getWorkshopInvoicesList(
      perPage,
      page,
      searchQuery,
      customerUUID,
      statuses
    )
      .then((resp) => {
        commit("SET_WORKSHOP_INVOICES_LIST", resp.data.results);
        commit("SET_WORKSHOP_INVOICES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopInvoicesListWithoutPagination({}) {
    return ServiceInvoices.getWorkshopInvoicesListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveWorkshopInvoice({}, invoiceUUID) {
    return ServiceInvoices.retrieveWorkshopInvoice(invoiceUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveWorkshopInvoiceCustomerView({}, invoiceUUID) {
    return ServiceInvoices.retrieveWorkshopInvoiceCustomerView(invoiceUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  createWorkshopInvoice({ commit }, formData) {
    return ServiceInvoices.createWorkshopInvoice(formData)
      .then((resp) => {
        commit("SAVE_INVOICE_CREATE_TASK_ID", resp.data.task_id);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Creating invoice. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkWorkshopInvoiceCreateTaskStatus({ commit }, formData) {
    return ServiceInvoices.checkWorkshopInvoiceCreateTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_INVOICE_CREATE_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateWorkshopInvoice({ commit }, { invoiceUUID, formData, updateType }) {
    return ServiceInvoices.updateWorkshopInvoice(invoiceUUID, formData)
      .then((resp) => {
        commit("UPDATE_WORKSHOP_INVOICE", resp.data.invoice_data);
        commit("SAVE_XERO_INVOICE_TASK_ID", resp.data.xero_invoice_task_id);

        if (updateType == "invoice") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Invoice updated successfully",
          });
        } else if (updateType == "note") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Invoice note updated successfully",
          });
        }
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.invoice_number) {
            message = "Invoice Number: " + err.response.data.invoice_number;
          } else if (err.response.data.reference) {
            message = "Reference: " + err.response.data.reference;
          } else if (err.response.data.currency) {
            message = "Currency: " + err.response.data.currency;
          } else if (err.response.data.line_amount_type) {
            message = "Line Amount Type: " + err.response.data.line_amount_type;
          } else if (err.response.data.include_discount) {
            message = "Include Discount: " + err.response.data.include_discount;
          } else if (err.response.data.due_date) {
            message = "Due Date: " + err.response.data.due_date;
          } else if (err.response.data.issue_date) {
            message = "Issue Date: " + err.response.data.issue_date;
          } else if (err.response.data.status) {
            message = "Status: " + err.response.data.status;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          } else if (err.response.data.invoicelineitem_set.length > 0) {
            for (const errObj of err.response.data.invoicelineitem_set) {
              if (Object.keys(errObj).length > 0) {
                if (errObj.seq_no) {
                  message = "Seq No.: " + errObj.seq_no;
                } else if (errObj.item_type) {
                  message = "Item: " + errObj.item_type;
                } else if (errObj.product) {
                  message = "Item: " + errObj.product;
                } else if (errObj.general_service) {
                  message = "Item: " + errObj.general_service;
                } else if (errObj.description) {
                  message = "Description: " + errObj.description;
                } else if (errObj.quantity) {
                  message = "Quantity: " + errObj.quantity;
                } else if (errObj.cost_price) {
                  message = "Cost Price: " + errObj.cost_price;
                } else if (errObj.price) {
                  message = "Price: " + errObj.price;
                } else if (errObj.tax_rate) {
                  message = "Tax Rate: " + errObj.tax_rate;
                } else if (errObj.xero_account) {
                  message = "Account: " + errObj.xero_account;
                } else if (errObj.discount_amount) {
                  message = "Discount Amount: " + errObj.discount_amount;
                } else if (errObj.total_amount) {
                  message = "Amount: " + errObj.total_amount;
                } else if (errObj.action_required) {
                  message = errObj.action_required[0];
                }
                break;
              }
            }
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  checkXeroInvoiceTaskStatus({ commit }, formData) {
    return ServiceInvoices.checkXeroInvoiceTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_XERO_INVOICE_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopInvoiceFormData({}) {
    return ServiceInvoices.getWorkshopInvoiceFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addInvoiceLineItemFromMechanicScreen({}, { invoiceUUID, formData }) {
    return ServiceInvoices.addInvoiceLineItemFromMechanicScreen(
      invoiceUUID,
      formData
    )
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invoice line item added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.product) {
            message = "Product: " + err.response.data.product;
          } else if (err.response.data.quantity) {
            message = "Quantity: " + err.response.data.quantity;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateInvoiceLineItemFromMechanicScreen({}, { invoiceUUID, formData }) {
    return ServiceInvoices.updateInvoiceLineItemFromMechanicScreen(
      invoiceUUID,
      formData
    )
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invoice line item updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.product) {
            message = "Product: " + err.response.data.product;
          } else if (err.response.data.quantity) {
            message = "Quantity: " + err.response.data.quantity;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteInvoiceLineItemFromMechanicScreen({}, { invoiceUUID, formData }) {
    return ServiceInvoices.deleteInvoiceLineItemFromMechanicScreen(
      invoiceUUID,
      formData
    )
      .then((resp) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Invoice line item deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  generateWorkshopInvoicePDF({ commit }, { invoiceUUID, templateName }) {
    return ServiceInvoices.generateWorkshopInvoicePDF(invoiceUUID, templateName)
      .then((resp) => {
        commit("SAVE_INVOICE_PDF_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating invoice pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkPDFGenerationTaskStatus({ commit }, { invoiceUUID, formData }) {
    return ServiceInvoices.checkPDFGenerationTaskStatus(invoiceUUID, formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_INVOICE_PDF_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  syncInvoiceInXero({}, invoiceUUID) {
    return ServiceInvoices.syncInvoiceInXero(invoiceUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Synced invoice in Xero successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          if (err.response.data.message) {
            let message = err.response.data.message;
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          } else {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: "Failed to sync invoice in Xero",
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Failed to sync invoice in Xero",
          });
        }
        return err.response;
      });
  },

  // xero account
  getXeroAccountsList({ commit }, { perPage, page }) {
    return ServiceInvoices.getXeroAccountsList(perPage, page)
      .then((resp) => {
        commit("SET_XERO_ACCOUNTS_LIST", resp.data.results);
        commit("SET_XERO_ACCOUNTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getXeroAccountsListWithoutPagination({}) {
    return ServiceInvoices.getXeroAccountsListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  syncXeroAccounts({ commit }) {
    return ServiceInvoices.syncXeroAccounts()
      .then((resp) => {
        commit("SET_XERO_ACCOUNTS_LIST", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Synced Xero Accounts successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.code_no) {
            message = "Code No.: " + err.response.data.code_no;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  toggleXeroAccountStatus({ commit }, objId) {
    return ServiceInvoices.toggleXeroAccountStatus(objId)
      .then((resp) => {
        commit("UPDATE_XERO_ACCOUNT", resp.data);
        if (resp.data.is_enabled) {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Xero Account is enabled to use",
          });
        } else if (!resp.data.is_enabled) {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Xero Account is disabled to use",
          });
        }
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // xero currency
  getXeroCurrenciesList({ commit }, { perPage, page }) {
    return ServiceInvoices.getXeroCurrenciesList(perPage, page)
      .then((resp) => {
        commit("SET_XERO_CURRENCIES_LIST", resp.data.results);
        commit("SET_XERO_CURRENCIES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getXeroCurrenciesListWithoutPagination({}) {
    return ServiceInvoices.getXeroCurrenciesListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  syncXeroCurrencies({ commit }) {
    return ServiceInvoices.syncXeroCurrencies()
      .then((resp) => {
        commit("SET_XERO_CURRENCIES_LIST", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Synced Xero Currencies successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.code_name) {
            message = "Code Name: " + err.response.data.code_name;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  setDefaultXeroCurrency({ commit }, objId) {
    return ServiceInvoices.setDefaultXeroCurrency(objId)
      .then((resp) => {
        commit("UPDATE_XERO_CURRENCY", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: `${resp.data.code_name} is set as default currency`,
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  shareWorkshopInvoiceWithCustomer({}, { invoiceUUID, formData }) {
    return ServiceInvoices.shareWorkshopInvoiceWithCustomer(
      invoiceUUID,
      formData
    )
      .then((resp) => {
        let msgTxt = "";
        if (formData.share_medium == "sms") {
          msgTxt = "Sending invoice URL to customer!";
        } else if (formData.share_medium == "mail") {
          msgTxt = "Sending invoice pdf to customer!";
        }

        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: msgTxt,
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  recordInvoicePayment({}, formData) {
    return ServiceInvoices.recordInvoicePayment(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Payment recorded successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.invoice) {
            message = "Invoice: " + err.response.data.invoice;
          } else if (err.response.data.payment_method) {
            message = "Payment Method: " + err.response.data.payment_method;
          } else if (err.response.data.amount) {
            message = "Amount: " + err.response.data.amount;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.additional_charge) {
            message =
              "Additional Charge: " + err.response.data.additional_charge;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getInvoicePaymentList({}, invoiceUUID) {
    return ServiceInvoices.getInvoicePaymentList(invoiceUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopCompletedInvoiceList({ commit }, { perPage, page, searchQuery }) {
    return ServiceInvoices.getWorkshopCompletedInvoiceList(
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_WORKSHOP_COMPLETED_INVOICE_LIST", resp.data.results);
        commit("SET_WORKSHOP_COMPLETED_INVOICE_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
