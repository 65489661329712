import ServiceCompanyUser from "../../service/company_user/ServiceCompanyUsers.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  currentCompanyUserInfo: {},

  companyUserInfo: {},
  companyUsersList: [],
  companyUsersCount: null,

  userCompanyList: [],
  userCompanyCount: null,
};

export const mutations = {
  SET_CURRENT_COMPANY_USER_INFO(state, data) {
    state.currentCompanyUserInfo = data;
  },

  SET_COMPANY_USER_INFO(state, data) {
    state.companyUserInfo = JSON.parse(JSON.stringify(data));
  },

  SET_COMPANY_USERS_LIST(state, data) {
    state.companyUsersList = data;
  },

  SET_COMPANY_USERS_COUNT(state, count) {
    state.companyUsersCount = count;
  },

  DELETE_COMPANY_USER(state, companyUserUUID) {
    state.companyUsersList = state.companyUsersList.filter((element) => {
      if (element.company_user_uuid != companyUserUUID) {
        return element;
      }
    });
  },

  UPDATE_COMPANY_USER(state, companyUserObj) {
    state.companyUsersList = state.companyUsersList.map((element) => {
      if (element.id === companyUserObj.id) {
        element = JSON.parse(JSON.stringify(companyUserObj));
      }
      return element;
    });
  },

  SET_USER_COMPANY_LIST(state, userCompanyList) {
    state.userCompanyList = JSON.parse(userCompanyList);
    state.userCompanyCount = JSON.parse(userCompanyList).length;
  },
};

export const actions = {
  retrieveCompanyUser({ commit }, companyUserUUID) {
    return ServiceCompanyUser.retrieveCompanyUser(companyUserUUID)
      .then((resp) => {
        commit("SET_COMPANY_USER_INFO", resp.data);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCompanyUser({ commit }, { companyUserUUID, formData, actionType }) {
    return ServiceCompanyUser.updateCompanyUser(companyUserUUID, formData)
      .then((resp) => {
        if (actionType == "profile") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Profile updated successfully",
          });
        } else if (actionType == "user") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "User updated successfully",
          });
        } else if (actionType == "image-upload") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Profile Image uploaded successfully",
          });
        } else if (actionType == "image-delete") {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Delete",
            message: "Profile Image deleted successfully",
          });
        } else if (actionType == "notification") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "User notification settings updated successfully",
          });
        } else if (actionType == "phone_number") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Phone number updated successfully",
          });
        } else if (actionType == "email") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Email updated successfully",
          });
        }

        commit("UPDATE_COMPANY_USER", resp.data);
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.first_name) {
            message = "First Name: " + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name: " + err.response.data.last_name;
          } else if (err.response.data.dob) {
            message = "Date of Birth: " + err.response.data.dob;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.image) {
            message = "Profile Image: " + err.response.data.image;
          } else if (err.response.data.labour_cost) {
            message = "Labour Cost: " + err.response.data.labour_cost;
          } else if (err.response.data.labour_rate) {
            message = "Labour Rate: " + err.response.data.labour_rate;
          } else if (err.response.data.display_in_dashboard) {
            message =
              "Display In Dashboard: " + err.response.data.display_in_dashboard;
          } else if (err.response.data.is_mechanic) {
            message = "Is Mechanic: " + err.response.data.is_mechanic;
          } else if (err.response.data.login_redirect_page) {
            message =
              "Login Redirect Page: " + err.response.data.login_redirect_page;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getCompanyUsersList(
    { commit },
    { perPage, page, searchQuery, permissionRoles, statuses }
  ) {
    return ServiceCompanyUser.getCompanyUsersList(
      perPage,
      page,
      searchQuery,
      permissionRoles,
      statuses
    )
      .then((resp) => {
        commit("SET_COMPANY_USERS_LIST", resp.data.results);
        commit("SET_COMPANY_USERS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCurrentCompanyUserInfo({ commit }) {
    return ServiceCompanyUser.getCurrentCompanyUserInfo()
      .then((resp) => {
        commit("SET_CURRENT_COMPANY_USER_INFO", resp.data);
        return resp.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCompanyUserFormData({}) {
    return ServiceCompanyUser.getCompanyUserFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  deleteCompanyUser({ commit }, companyUserUUID) {
    return ServiceCompanyUser.deleteCompanyUser(companyUserUUID)
      .then((resp) => {
        commit("DELETE_COMPANY_USER", companyUserUUID);

        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "User deleted successfully",
        });

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  suspendCompanyUser({ commit }, companyUserUUID) {
    return ServiceCompanyUser.suspendCompanyUser(companyUserUUID)
      .then((resp) => {
        commit("UPDATE_COMPANY_USER", resp.data);

        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Suspended",
          message: "User suspended successfully",
        });

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  activeCompanyUser({ commit }, companyUserUUID) {
    return ServiceCompanyUser.activeCompanyUser(companyUserUUID)
      .then((resp) => {
        commit("UPDATE_COMPANY_USER", resp.data);

        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Activated",
          message: "User activated successfully",
        });

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getUserCompanyList({ commit }) {
    return ServiceCompanyUser.getUserCompanyList()
      .then((resp) => {
        commit("SET_USER_COMPANY_LIST", resp.data);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  selectCompany({}, formData) {
    return ServiceCompanyUser.selectCompany(formData)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        console.log(err);
        return err.response;
      });
  },

  changePermissions({}, formData) {
    return ServiceCompanyUser.changePermissions(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Permissions updated succesfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        console.log(err);
        return err.response;
      });
  },
};
