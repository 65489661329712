import ServiceInventory from "../../service/inventory/ServiceInventory.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  productInfo: {},

  tyreProductsList: [],
  tyreProductsCount: null,

  wheelProductsList: [],
  wheelProductsCount: null,

  generalProductsList: [],
  generalProductsCount: null,

  trackedInventoryProductsList: [],
  trackedInventoryProductsCount: null,

  productDocumentsList: [],
  productDocumentsCount: null,

  brandsList: [],
  brandsCount: null,

  suppliersList: [],
  suppliersCount: null,

  productTypesList: [],
  productTypesCount: null,

  inventoryStockOnHandList: [],
  inventoryStockOnHandCount: null,

  inventoryProductPerformanceList: [],
  inventoryProductPerformanceCount: null,

  jobsInventoryProductPerformanceList: [],
  jobsInventoryProductPerformanceCount: null,

  inventoryLowStockList: [],
  inventoryLowStockCount: null,

  inventoryLowStockReportPdfTaskId: null,
  inventoryLowStockReportExcelTaskId: null,
};

export const mutations = {
  // products
  SET_PRODUCT_INFO(state, data) {
    state.productInfo = data;
  },

  SET_TYRE_PRODUCTS_LIST(state, data) {
    state.tyreProductsList = data;
  },

  SET_TYRE_PRODUCTS_COUNT(state, count) {
    state.tyreProductsCount = count;
  },

  SET_WHEEL_PRODUCTS_LIST(state, data) {
    state.wheelProductsList = data;
  },

  SET_WHEEL_PRODUCTS_COUNT(state, count) {
    state.wheelProductsCount = count;
  },

  SET_GENERAL_PRODUCTS_LIST(state, data) {
    state.generalProductsList = data;
  },

  SET_GENERAL_PRODUCTS_COUNT(state, count) {
    state.generalProductsCount = count;
  },

  SET_TRACKED_INVENTORY_PRODUCTS_LIST(state, data) {
    state.trackedInventoryProductsList = data;
  },

  SET_TRACKED_INVENTORY_PRODUCTS_COUNT(state, count) {
    state.trackedInventoryProductsCount = count;
  },

  ADD_TYRE_PRODUCT(state, product) {
    state.tyreProductsList.unshift(product);
  },

  UPDATE_TYRE_PRODUCT(state, product) {
    state.tyreProductsList = state.tyreProductsList.map((element) => {
      if (element.id === product.id) {
        element = JSON.parse(JSON.stringify(product));
      }
      return element;
    });
  },

  DELETE_TYRE_PRODUCT(state, productUUID) {
    state.tyreProductsList = state.tyreProductsList.filter((element) => {
      if (element.product_uuid != productUUID) {
        return element;
      }
    });
  },

  ADD_WHEEL_PRODUCT(state, product) {
    state.wheelProductsList.unshift(product);
  },

  UPDATE_WHEEL_PRODUCT(state, product) {
    state.wheelProductsList = state.wheelProductsList.map((element) => {
      if (element.id === product.id) {
        element = JSON.parse(JSON.stringify(product));
      }
      return element;
    });
  },

  DELETE_WHEEL_PRODUCT(state, productUUID) {
    state.wheelProductsList = state.wheelProductsList.filter((element) => {
      if (element.product_uuid != productUUID) {
        return element;
      }
    });
  },

  ADD_GENERAL_PRODUCT(state, product) {
    state.generalProductsList.unshift(product);
  },

  UPDATE_GENERAL_PRODUCT(state, product) {
    state.generalProductsList = state.generalProductsList.map((element) => {
      if (element.id === product.id) {
        element = JSON.parse(JSON.stringify(product));
      }
      return element;
    });
  },

  DELETE_GENERAL_PRODUCT(state, productUUID) {
    state.generalProductsList = state.generalProductsList.filter((element) => {
      if (element.product_uuid != productUUID) {
        return element;
      }
    });
  },

  // product document
  SET_PRODUCT_DOCUMENTS_LIST(state, data) {
    state.productDocumentsList = data;
  },

  SET_PRODUCT_DOCUMENTS_COUNT(state, count) {
    state.productDocumentsCount = count;
  },

  ADD_PRODUCT_DOCUMENT(state, productDocument) {
    state.productDocumentsList.unshift(productDocument);
  },

  UPDATE_PRODUCT_DOCUMENT(state, productDocument) {
    state.productDocumentsList = state.productDocumentsList.map((element) => {
      if (element.id === productDocument.id) {
        element = JSON.parse(JSON.stringify(productDocument));
      }
      return element;
    });
  },

  DELETE_PRODUCT_DOCUMENT(state, productDocumentUUID) {
    state.productDocumentsList = state.productDocumentsList.filter(
      (element) => {
        if (element.document_uuid !== productDocumentUUID) {
          return element;
        }
      }
    );
  },

  // brands
  SET_BRANDS_LIST(state, data) {
    state.brandsList = data;
  },

  SET_BRANDS_COUNT(state, count) {
    state.brandsCount = count;
  },

  ADD_BRAND(state, brand) {
    state.brandsList.unshift(brand);
  },

  UPDATE_BRAND(state, brand) {
    state.brandsList = state.brandsList.map((element) => {
      if (element.id === brand.id) {
        element = JSON.parse(JSON.stringify(brand));
      }
      return element;
    });
  },

  DELETE_BRAND(state, objId) {
    state.brandsList = state.brandsList.filter((element) => {
      if (element.id != objId) {
        return element;
      }
    });
  },

  // suppliers
  SET_SUPPLIERS_LIST(state, data) {
    state.suppliersList = data;
  },

  SET_SUPPLIERS_COUNT(state, count) {
    state.suppliersCount = count;
  },

  ADD_SUPPLIER(state, supplier) {
    state.suppliersList.unshift(supplier);
  },

  UPDATE_SUPPLIER(state, supplier) {
    state.suppliersList = state.suppliersList.map((element) => {
      if (element.id === supplier.id) {
        element = JSON.parse(JSON.stringify(supplier));
      }
      return element;
    });
  },

  DELETE_SUPPLIER(state, objId) {
    state.suppliersList = state.suppliersList.filter((element) => {
      if (element.id != objId) {
        return element;
      }
    });
  },

  // productTypes
  SET_PRODUCT_TYPES_LIST(state, data) {
    state.productTypesList = data;
  },

  SET_PRODUCT_TYPES_COUNT(state, count) {
    state.productTypesCount = count;
  },

  ADD_PRODUCT_TYPE(state, productType) {
    state.productTypesList.unshift(productType);
  },

  UPDATE_PRODUCT_TYPE(state, productType) {
    state.productTypesList = state.productTypesList.map((element) => {
      if (element.id === productType.id) {
        element = JSON.parse(JSON.stringify(productType));
      }
      return element;
    });
  },

  DELETE_PRODUCT_TYPE(state, objId) {
    state.productTypesList = state.productTypesList.filter((element) => {
      if (element.id != objId) {
        return element;
      }
    });
  },

  // inventory reports
  SET_INVENTORY_STOCK_ON_HAND_LIST(state, data) {
    let serializerData = data.serializer_data;
    let totalStatsObj = data.total_stats_data;
    let firstRow = {
      id: "",
      product_uuid: "",
      name: "",
      part_number: "total_stats",
      product_type_display: "",
      brand_display: "",
      supplier_display: "",
      department_display: "",
      total_cost: totalStatsObj.total_cost,
      total_gross_profit: totalStatsObj.total_gross_profit,
      total_revenue: totalStatsObj.total_revenue,
      current_inventory: totalStatsObj.total_stock,
    };
    state.inventoryStockOnHandList = serializerData;
    state.inventoryStockOnHandList.unshift(firstRow);
  },

  SET_INVENTORY_STOCK_ON_HAND_COUNT(state, count) {
    state.inventoryStockOnHandCount = count;
  },

  SET_INVENTORY_PRODUCT_PERFORMANCE_LIST(state, data) {
    state.inventoryProductPerformanceList = data;
  },

  SET_INVENTORY_PRODUCT_PERFORMANCE_COUNT(state, count) {
    state.inventoryProductPerformanceCount = count;
  },

  SET_JOBS_INVENTORY_PRODUCT_PERFORMANCE_LIST(state, data) {
    state.jobsInventoryProductPerformanceList = data;
  },

  SET_JOBS_INVENTORY_PRODUCT_PERFORMANCE_COUNT(state, count) {
    state.jobsInventoryProductPerformanceCount = count;
  },

  SET_INVENTORY_LOW_STOCK_LIST(state, data) {
    state.inventoryLowStockList = data;
  },

  SET_INVENTORY_LOW_STOCK_COUNT(state, count) {
    state.inventoryLowStockCount = count;
  },

  SAVE_INVENTORY_LOW_STOCK_REPORT_PDF_TASK_ID(state, taskId) {
    state.inventoryLowStockReportPdfTaskId = taskId;
  },

  CLEAR_INVENTORY_LOW_STOCK_REPORT_PDF_TASK_ID(state) {
    state.inventoryLowStockReportPdfTaskId = null;
  },

  SAVE_INVENTORY_LOW_STOCK_REPORT_EXCEL_TASK_ID(state, taskId) {
    state.inventoryLowStockReportExcelTaskId = taskId;
  },

  CLEAR_INVENTORY_LOW_STOCK_REPORT_EXCEL_TASK_ID(state) {
    state.inventoryLowStockReportExcelTaskId = null;
  },
};

export const actions = {
  // products
  getProductsListWithoutPagination(
    {},
    { searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.getProductsListWithoutPagination(
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getTyreProductsList(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers }
  ) {
    return ServiceInventory.getTyreProductsList(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers
    )
      .then((resp) => {
        commit("SET_TYRE_PRODUCTS_LIST", resp.data.results);
        commit("SET_TYRE_PRODUCTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWheelProductsList(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers }
  ) {
    return ServiceInventory.getWheelProductsList(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers
    )
      .then((resp) => {
        commit("SET_WHEEL_PRODUCTS_LIST", resp.data.results);
        commit("SET_WHEEL_PRODUCTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getGeneralProductsList(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.getGeneralProductsList(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit("SET_GENERAL_PRODUCTS_LIST", resp.data.results);
        commit("SET_GENERAL_PRODUCTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getTrackedInventoryProductsList(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.getTrackedInventoryProductsList(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit("SET_TRACKED_INVENTORY_PRODUCTS_LIST", resp.data.results);
        commit("SET_TRACKED_INVENTORY_PRODUCTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getMechanicScreenDisplayProductsListWithoutPagination({}) {
    return ServiceInventory.getMechanicScreenDisplayProductsListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getVehicleRecommendedProductsListWithoutPagination(
    {},
    { vehicleUUID, searchQuery }
  ) {
    return ServiceInventory.getVehicleRecommendedProductsListWithoutPagination(
      vehicleUUID,
      searchQuery
    )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addProduct({ commit }, { productTypeSlug, formData }) {
    return ServiceInventory.addProduct(formData)
      .then((resp) => {
        if (productTypeSlug == "tyre") {
          commit("ADD_TYRE_PRODUCT", resp.data);
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Tyre added successfully",
          });
        } else if (productTypeSlug == "wheel") {
          commit("ADD_WHEEL_PRODUCT", resp.data);
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Wheel added successfully",
          });
        } else {
          commit("ADD_GENERAL_PRODUCT", resp.data);
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Product added successfully",
          });
        }

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.part_number) {
            message = "Part Number: " + err.response.data.part_number;
          } else if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.product_type) {
            message = "Product Type: " + err.response.data.product_type;
          } else if (err.response.data.brand) {
            message = "Brand: " + err.response.data.brand;
          } else if (err.response.data.supplier) {
            message = "Supplier: " + err.response.data.supplier;
          } else if (err.response.data.supplier_product_code) {
            message =
              "Supplier Product Code: " +
              err.response.data.supplier_product_code;
          } else if (err.response.data.supplier_price) {
            message = "Supplier Price: " + err.response.data.supplier_price;
          } else if (err.response.data.markup_percentage) {
            message =
              "Markup Percentage: " + err.response.data.markup_percentage;
          } else if (err.response.data.retail_price) {
            message = "Retail Price: " + err.response.data.retail_price;
          } else if (err.response.data.tax_amount) {
            message = "Tax Amount: " + err.response.data.tax_amount;
          } else if (err.response.data.quantity) {
            message = "Quantity: " + err.response.data.quantity;
          } else if (err.response.data.measurement_unit) {
            message = "Measurement Unit: " + err.response.data.measurement_unit;
          } else if (err.response.data.track_inventory) {
            message = "Track Inventory: " + err.response.data.track_inventory;
          } else if (err.response.data.current_inventory) {
            message =
              "Current Inventory: " + err.response.data.current_inventory;
          } else if (err.response.data.reorder_point) {
            message = "Re-order Point: " + err.response.data.reorder_point;
          } else if (err.response.data.reorder_quantity) {
            message =
              "Re-order Quantity: " + err.response.data.reorder_quantity;
          } else if (err.response.data.tax_rate) {
            message = "Tax Rate: " + err.response.data.tax_rate;
          } else if (err.response.data.wheel_data) {
            message = "Wheel Data: " + err.response.data.wheel_data;
          } else if (err.response.data.tyre_data) {
            message = "Tyre Data: " + err.response.data.tyre_data;
          } else if (err.response.data.list_on_web) {
            message = "List On Web: " + err.response.data.list_on_web;
          } else if (err.response.data.web_display_data) {
            message = "Web Display Data: " + err.response.data.web_display_data;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.productphoto_set) {
            for (const obj of err.response.data.productphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveProduct({}, productUUID) {
    return ServiceInventory.retrieveProduct(productUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateProduct({ commit }, { productTypeSlug, productUUID, formData }) {
    return ServiceInventory.updateProduct(productUUID, formData)
      .then((resp) => {
        if (productTypeSlug == "tyre") {
          commit("UPDATE_TYRE_PRODUCT", resp.data);
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Tyre updated successfully",
          });
        } else if (productTypeSlug == "wheel") {
          commit("UPDATE_WHEEL_PRODUCT", resp.data);
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Wheel updated successfully",
          });
        } else {
          commit("UPDATE_GENERAL_PRODUCT", resp.data);
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Product updated successfully",
          });
        }

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.part_number) {
            message = "Part Number: " + err.response.data.part_number;
          } else if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.product_type) {
            message = "Product Type: " + err.response.data.product_type;
          } else if (err.response.data.brand) {
            message = "Brand: " + err.response.data.brand;
          } else if (err.response.data.supplier) {
            message = "Supplier: " + err.response.data.supplier;
          } else if (err.response.data.supplier_product_code) {
            message =
              "Supplier Product Code: " +
              err.response.data.supplier_product_code;
          } else if (err.response.data.supplier_price) {
            message = "Supplier Price: " + err.response.data.supplier_price;
          } else if (err.response.data.markup_percentage) {
            message =
              "Markup Percentage: " + err.response.data.markup_percentage;
          } else if (err.response.data.retail_price) {
            message = "Retail Price: " + err.response.data.retail_price;
          } else if (err.response.data.tax_amount) {
            message = "Tax Amount: " + err.response.data.tax_amount;
          } else if (err.response.data.quantity) {
            message = "Quantity: " + err.response.data.quantity;
          } else if (err.response.data.measurement_unit) {
            message = "Measurement Unit: " + err.response.data.measurement_unit;
          } else if (err.response.data.track_inventory) {
            message = "Track Inventory: " + err.response.data.track_inventory;
          } else if (err.response.data.current_inventory) {
            message =
              "Current Inventory: " + err.response.data.current_inventory;
          } else if (err.response.data.reorder_point) {
            message = "Re-order Point: " + err.response.data.reorder_point;
          } else if (err.response.data.reorder_quantity) {
            message =
              "Re-order Quantity: " + err.response.data.reorder_quantity;
          } else if (err.response.data.tax_rate) {
            message = "Tax Rate: " + err.response.data.tax_rate;
          } else if (err.response.data.wheel_data) {
            message = "Wheel Data: " + err.response.data.wheel_data;
          } else if (err.response.data.tyre_data) {
            message = "Tyre Data: " + err.response.data.tyre_data;
          } else if (err.response.data.list_on_web) {
            message = "List On Web: " + err.response.data.list_on_web;
          } else if (err.response.data.web_display_data) {
            message = "Web Display Data: " + err.response.data.web_display_data;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.productphoto_set) {
            for (const obj of err.response.data.productphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteProduct({ commit }, { productTypeSlug, productUUID }) {
    return ServiceInventory.deleteProduct(productUUID)
      .then((resp) => {
        if (productTypeSlug == "tyre") {
          commit("DELETE_TYRE_PRODUCT", productUUID);
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Delete",
            message: "Tyre deleted successfully",
          });
        } else if (productTypeSlug == "wheel") {
          commit("DELETE_WHEEL_PRODUCT", productUUID);
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Delete",
            message: "Wheel deleted successfully",
          });
        } else {
          commit("DELETE_GENERAL_PRODUCT", productUUID);
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Delete",
            message: "Product deleted successfully",
          });
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getProductFormData({}) {
    return ServiceInventory.getProductFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // product document
  getProductDocumentsList({ commit }, { productUUID, perPage, page }) {
    return ServiceInventory.getProductDocumentsList(productUUID, perPage, page)
      .then((resp) => {
        commit("SET_PRODUCT_DOCUMENTS_LIST", resp.data.results);
        commit("SET_PRODUCT_DOCUMENTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addProductDocument({ commit }, { productUUID, formData }) {
    return ServiceInventory.addProductDocument(productUUID, formData)
      .then((resp) => {
        commit("ADD_PRODUCT_DOCUMENT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Product Document uploaded successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.file) {
            message = err.response.data.file;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateProductDocument(
    { commit },
    { productUUID, productDocumentUUID, formData }
  ) {
    return ServiceInventory.updateProductDocument(
      productUUID,
      productDocumentUUID,
      formData
    )
      .then((resp) => {
        commit("UPDATE_PRODUCT_DOCUMENT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Product Document updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.file) {
            message = err.response.data.file;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteProductDocument({ commit }, { productUUID, productDocumentUUID }) {
    return ServiceInventory.deleteProductDocument(
      productUUID,
      productDocumentUUID
    )
      .then((resp) => {
        commit("DELETE_PRODUCT_DOCUMENT", productDocumentUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Product Document deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // brands
  getBrandsList({ commit }, { perPage, page, searchQuery }) {
    return ServiceInventory.getBrandsList(perPage, page, searchQuery)
      .then((resp) => {
        commit("SET_BRANDS_LIST", resp.data.results);
        commit("SET_BRANDS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addBrand({ commit }, formData) {
    return ServiceInventory.addBrand(formData)
      .then((resp) => {
        commit("ADD_BRAND", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Brand added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateBrand({ commit }, { objId, formData }) {
    return ServiceInventory.updateBrand(objId, formData)
      .then((resp) => {
        commit("UPDATE_BRAND", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Brand updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteBrand({ commit }, objId) {
    return ServiceInventory.deleteBrand(objId)
      .then((resp) => {
        commit("DELETE_BRAND", objId);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Brand deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // suppliers
  getSuppliersList({ commit }, { perPage, page, searchQuery }) {
    return ServiceInventory.getSuppliersList(perPage, page, searchQuery)
      .then((resp) => {
        commit("SET_SUPPLIERS_LIST", resp.data.results);
        commit("SET_SUPPLIERS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addSupplier({ commit }, formData) {
    return ServiceInventory.addSupplier(formData)
      .then((resp) => {
        commit("ADD_SUPPLIER", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Supplier added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateSupplier({ commit }, { objId, formData }) {
    return ServiceInventory.updateSupplier(objId, formData)
      .then((resp) => {
        commit("UPDATE_SUPPLIER", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Supplier updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteSupplier({ commit }, objId) {
    return ServiceInventory.deleteSupplier(objId)
      .then((resp) => {
        commit("DELETE_SUPPLIER", objId);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Supplier deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // product types
  getProductTypesList({ commit }, { perPage, page, searchQuery }) {
    return ServiceInventory.getProductTypesList(perPage, page, searchQuery)
      .then((resp) => {
        commit("SET_PRODUCT_TYPES_LIST", resp.data.results);
        commit("SET_PRODUCT_TYPES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getProductTypesListWithoutPagination({}) {
    return ServiceInventory.getProductTypesListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addProductType({ commit }, formData) {
    return ServiceInventory.addProductType(formData)
      .then((resp) => {
        commit("ADD_PRODUCT_TYPE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Product Type added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateProductType({ commit }, { objId, formData }) {
    return ServiceInventory.updateProductType(objId, formData)
      .then((resp) => {
        commit("UPDATE_PRODUCT_TYPE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Product Type updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteProductType({ commit }, objId) {
    return ServiceInventory.deleteProductType(objId)
      .then((resp) => {
        commit("DELETE_PRODUCT_TYPE", objId);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Product Type deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // inventory reports
  getInventoryStockOnHandReport(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.getInventoryStockOnHandReport(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit("SET_INVENTORY_STOCK_ON_HAND_LIST", resp.data.results);
        commit("SET_INVENTORY_STOCK_ON_HAND_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getInventoryProductPerformanceReport(
    { commit },
    {
      perPage,
      page,
      timePeriod,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes,
    }
  ) {
    return ServiceInventory.getInventoryProductPerformanceReport(
      perPage,
      page,
      timePeriod,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit("SET_INVENTORY_PRODUCT_PERFORMANCE_LIST", resp.data.results);
        commit("SET_INVENTORY_PRODUCT_PERFORMANCE_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobsProductPerformance(
    { commit },
    { productUUID, timePeriod, perPage, page }
  ) {
    return ServiceInventory.getJobsProductPerformance(
      productUUID,
      timePeriod,
      perPage,
      page
    )
      .then((resp) => {
        commit(
          "SET_JOBS_INVENTORY_PRODUCT_PERFORMANCE_LIST",
          resp.data.results.jobs_data
        );
        commit("SET_JOBS_INVENTORY_PRODUCT_PERFORMANCE_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getInventoryLowStockReport(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.getInventoryLowStockReport(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit("SET_INVENTORY_LOW_STOCK_LIST", resp.data.results);
        commit("SET_INVENTORY_LOW_STOCK_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateInventoryLowStockReportPDF(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.generateInventoryLowStockReportPDF(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit(
          "SAVE_INVENTORY_LOW_STOCK_REPORT_PDF_TASK_ID",
          resp.data.task_id
        );
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating report pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkInventoryLowStockReportPDFTaskStatus({ commit }, formData) {
    return ServiceInventory.checkInventoryLowStockReportPDFTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_INVENTORY_LOW_STOCK_REPORT_PDF_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateInventoryLowStockReportExcel(
    { commit },
    { perPage, page, searchQuery, departments, brands, suppliers, productTypes }
  ) {
    return ServiceInventory.generateInventoryLowStockReportExcel(
      perPage,
      page,
      searchQuery,
      departments,
      brands,
      suppliers,
      productTypes
    )
      .then((resp) => {
        commit(
          "SAVE_INVENTORY_LOW_STOCK_REPORT_EXCEL_TASK_ID",
          resp.data.task_id
        );
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating excel report. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkInventoryLowStockReportExcelTaskStatus({ commit }, formData) {
    return ServiceInventory.checkInventoryLowStockReportExcelTaskStatus(
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_INVENTORY_LOW_STOCK_REPORT_EXCEL_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
